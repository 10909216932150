<template>
  <div class="reservation-wrapper reservation">
    <div class="reservation-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-0 py-1">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <span
                  class="white--text"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                    height: 25px !important;
                    font-size: 17px;
                  "
                >
                  {{ t('reservations.booking_details') }}
                </span>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-1 no-bg-hover"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text>
        <info-card
          :title="dataComputed.sport_name"
          :subtitle="dataComputed.subtotal"
          :date="dataComputed.reservation_date_str"
          :start-time="dataComputed.start_time_formatted ? `${dataComputed.start_time_formatted} (${formatTime(dataComputed.reservation_time)})` : ''"
          :location="dataComputed.complex_name ? `${dataComputed.complex_name} • ${dataComputed.pitch_name}` : ''"
          :cancel-date="dataComputed.cancel_reservation_date_str"
          :is-cancel="dataComputed.reservation_status === 'cancelled'"
          :can-cancel="dataComputed.can_cancel"
          :is-confirm="!!dataComputed.deposit_card"
          :confirm-code="depositCardComputed"
          :confirm-date="dataComputed.cancel_reservation_date_str"
          :is-paid="dataComputed.is_paid === 'Y'"
          :is-now="dataComputed.charge_reservation_date_str === 'now'"
          :payment-status="dataComputed.reservation_status === 'cancelled' ? dataComputed.reservation_status : dataComputed.payment_status"
        />
      </v-card-text>
      <v-divider
        style="color: #191919"
      />

      <v-card-text
        v-if="dataComputed.reservation_status !== 'cancelled'"
      >
        <v-row
          class="py-3"
          :class="{'px-1': activeComputed, 'px-3': !activeComputed}"
          :justify="activeComputed ? 'space-around' : 'start'"
        >
          <v-col
            cols="3"
            md="3"
            sm="3"
            class="pa-1 d-flex justify-center align-center"
            @click="onShare"
          >
            <action
              :title="t('tooltip.share')"
              :icon="icons.mdiTrayArrowUp"
              :is-row="false"
            />
          </v-col>

          <v-col
            v-if="dataComputed.showvideo"
            cols="3"
            md="3"
            sm="3"
            class="pa-1 d-flex justify-center align-center"
            @click="linkVideo"
          >
            <action
              :title="t('videos.video')"
              :icon="icons.mdiMovieOpenPlayOutline"
              color-icon="#AAAAAA"
              color-title="#AAAAAA"
            />
          </v-col>

          <v-col
            v-if="dataComputed.can_cancel"
            cols="3"
            md="3"
            sm="3"
            class="pa-1 d-flex justify-center align-center"
            @click="openUpdateCancel(true)"
          >
            <action
              :title="t('tooltip.cancel')"
              :icon="icons.mdiClose"
              color-icon="#AAAAAA"
              color-title="#AAAAAA"
            />
          </v-col>

          <v-col
            cols="3"
            md="3"
            sm="3"
            class="pa-1 d-flex justify-center align-center"
            @click="openTab(dataComputed.complex_location, '_blank')"
          >
            <action
              :title="t('facilities.location')"
              :icon="icons.mdiMapMarker"
              color-icon="#AAAAAA"
              color-title="#AAAAAA"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider
        v-if="dataComputed.reservation_status !== 'cancelled'"
        style="color: #191919"
      />

      <v-card-text>
        <v-row
          v-if="dataComputed.show_video"
          class="px-3 py-1"
        >
          <v-col
            cols="12"
            class="pa-1 d-flex justify-space-between align-center lighterGray--text"
          >
            {{ t('reservations.video_recording_title').toUpperCase() }}
          </v-col>
        </v-row>

        <v-row
          v-if="dataComputed.show_video"
          class="px-0 py-1 ma-0"
        >
          <v-col
            cols="12"
            class="d-flex justify-center align-center"
          >
            <v-carousel
              width="auto"
              height="250px"
              class="rounded-lg cursor-pointer"
              hide-delimiters
              show-arrows-on-hover
              cycle
              :show-arrows="false"
            >
              <v-carousel-item
                v-if="dataComputed.thumbnail"
                :src="dataComputed.thumbnail"
                eager
                class="bg-grey"
                @click="linkVideo"
              >
                <video-icon
                  :time="dataComputed.video_time"
                  :icon="icons.mdiPlay"
                  icon-color="white"
                />
              </v-carousel-item>

              <div v-else-if="dataComputed.thumbnails && dataComputed.thumbnails.some(e => !e.includes('no-thumb'))">
                <v-carousel-item
                  v-for="(item, index) in dataComputed.thumbnails.filter(e => !e.includes('no-thumb'))"
                  :key="index"
                  :src="item"
                  eager
                  class="bg-grey"
                  @click="linkVideo"
                >
                  <video-icon
                    :time="dataComputed.video_time"
                    :icon="icons.mdiPlay"
                    icon-color="white"
                  />
                </v-carousel-item>
              </div>
              <v-carousel-item
                v-else
                eager
                class="bg-grey"
                @click="linkVideo"
              >
                <video-icon
                  :time="dataComputed.video_time"
                  :icon="icons.mdiPlay"
                  icon-color="white"
                />
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <video-card
          v-else
          title="VIDEO"
          :card-title="t('reservations.video_recording_title')"
          :card-status="resolveVideoCardStatus"
          :card-text="resolveVideoCardText"
          :card-border="resolveVideoCardBorder"
          :show-card-text="dataComputed.subtotal > 0"
        />
      </v-card-text>
      <v-divider
        style="color: #191919"
      />

      <v-card-text v-if="dataComputed.highlights && dataComputed.highlights.length">
        <highlight-list
          :title="`${t('highlights.highlights').toUpperCase()} (${dataComputed.highlights.length})`"
          :highlights="dataComputed.highlights"
          :is-private="privateLanding"
          :ip-address="ipAddress"
          :match-code="matchCode"
          @createHighlights="openUpdateDownload(true)"
        />
      </v-card-text>
      <v-divider
        v-if="dataComputed.highlights && dataComputed.highlights.length"
        style="color: #191919"
      />

      <v-card-text
        v-if="showComplexComputed"
        :style="`margin-bottom: ${showPayComputed ? '86px;' : '0'}`"
      >
        <v-row class="px-3 py-1">
          <v-col
            cols="12"
            class="pa-1 d-flex justify-space-between align-center lighterGray--text"
          >
            {{ t('facilities.location').toUpperCase() }}
          </v-col>
        </v-row>

        <v-row class="pa-1">
          <v-col
            cols="12"
            class="py-2 px-3 d-flex justify-space-between align-center cursor-pointer"
            @click="openTab(dataComputed.complex_location, '_blank')"
          >
            <div class="d-flex">
              <v-avatar
                color="#333333"
                :class="dataComputed.complex_logo ? '' : 'white--text'"
                size="50"
              >
                <v-img
                  v-if="dataComputed.complex_logo"
                  :src="dataComputed.complex_logo"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(dataComputed.complex_name) }}</span>
              </v-avatar>
              <div class="d-flex flex-column ml-2 justify-center">
                <span class="white--text">{{ dataComputed.complex_name }}</span>
                <span class="text-xs">{{ dataComputed.pitch_name }}</span>
                <span class="text-xs">{{ dataComputed.complex_address }}</span>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="pa-1">
          <v-col
            cols="12"
            class="px-3 d-flex justify-space-between align-center"
          >
            <v-carousel
              height="205px"
              width="auto"
              hide-delimiter-background
              cycle
              class="rounded-lg"
              :show-arrows="false"
            >
              <div>
                <v-carousel-item
                  v-for=" (item, index) in dataComputed.complex_images"
                  :key="index"
                  :src="item"
                  :style="dataComputed.match_type === 'O' ? 'opacity: 0.7;' : ''"
                  eager
                />
              </div>
            </v-carousel>
          </v-col>

          <v-col
            cols="12"
            class="px-3 lighterGray--text"
            v-html="textBreakLine(dataComputed.complex_description)"
          />
        </v-row>
      </v-card-text>
      <v-spacer
        v-else
        :style="`margin-bottom: ${showPayComputed ? '86px;' : '0'}`"
      />

      <v-card-actions
        v-if="showPayComputed"
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 mb-2"
          color="secondary text-capitalize"
          block
          style="border-radius: 33px;"
          @click="openUpdatePay(true)"
        >
          {{ t('transactions.pay_now') }}
        </v-btn>

        <span
          class="text-center text-sm mt-2"
        >
          {{ dataComputed.can_cancel ? `${t('reservations.free_cancellation')} ${dataComputed.cancel_reservation_date_str}` : t('reservations.non_refundable') }}
        </span>
      </v-card-actions>
    </div>

    <report
      v-model="isDialogReport"
      @open-close="openCloseReport"
      @report-issue="onReportIssue"
    />

    <download-app
      :is-open.sync="isDialogDownload"
      @open-close="openUpdateDownload"
      @save="defaultDownloadAppByOS"
    />

    <cancel-reservation
      :is-open.sync="isDialogCancel"
      @open-close="openUpdateCancel"
      @onSave="onCancelReservation"
    />

    <pay
      :is-open.sync="isDialogPay"
      :taxes="dataComputed.taxes"
      :subtotal="dataComputed.subtotal"
      :total="dataComputed.total"
      :cancel-date="dataComputed.cancel_reservation_date_str"
      :can-cancel="dataComputed.can_cancel"
      :payment-methods="paymentMethods"
      @open-close="openUpdatePay"
      @save="onPay"
    />

    <share
      :is-open.sync="isDialogShare"
      :link="dataComputed.share_link"
      :icon-size="28"
      :sport="dataComputed.sport_name"
      :date="dataComputed.reservation_date_str"
      :start-time="dataComputed.start_time_formatted ? `${dataComputed.start_time_formatted} (${dataComputed.reservation_time})` : ''"
      :location="dataComputed.complex_name ? `${dataComputed.complex_name} • ${dataComputed.pitch_name}` : ''"
      @open-close="openUpdateShare"
    />
  </div>
</template>
<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiTrayArrowUp,
  mdiDotsVertical,
  mdiCircle,
  mdiMapMarker,
  mdiCancel,
  mdiPlay,
  mdiStarOutline,
  mdiVideo,
  mdiPencil,
  mdiMovieOpenPlayOutline,
  mdiHelpCircleOutline,
  mdiClose,
} from '@mdi/js'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useRouter, openTab, isAndroid, isIOS, defaultDownloadAppByOS, isDev } from '@core/utils'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  reportIssue,
  logUserActivity,
  getReservation,
  putCancelReservation,
  putHoldCardReservation,
} from '@api'
import { error, success } from '@/@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import useShare from '@/@core/utils/useShare'
import useLogData from '@core/utils/useLogData'
import useVideoData from '@core/utils/useVideoData'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import Action from '@/views/reservation/components/action/Action.vue'
import HighlightList from '@/views/reservation/components/highlight-list/HighlightList.vue'
import Report from '@/views/reservation/components/report/Report.vue'
import VideoCard from '@/views/reservation/components/video-card/VideoCard.vue'
import InfoCard from '@/views/reservation/components/info-card/InfoCard.vue'
import VideoIcon from '@/views/reservation/components/video-icon/VideoIcon.vue'
import DownloadApp from '@/views/reservation/components/download-app/DownloadApp.vue'
import CancelReservation from '@/views/reservation/components/cancel/CancelReservation.vue'
import Pay from '@/views/reservation/components/pay/Pay.vue'
import Share from '@/views/components/share/Share.vue'

import useReservation from './useReservation'

export default {
  name: 'Reservation',
  components: {
    AppBarUserAvatar,
    Action,
    HighlightList,
    Report,
    InfoCard,
    VideoCard,
    VideoIcon,
    DownloadApp,
    CancelReservation,
    Pay,
    Share,
  },
  setup() {
    const { route, router } = useRouter()
    const { t } = useUtils()
    const { decryptData, userData } = useCryptoJs()
    const { shareURL, isSupportedShare } = useShare()
    const { logData, updateLogData, clearLogData } = useLogData()
    const { clearVideoData } = useVideoData()
    const { paymentMethods, fetchPaymentMethods } = useReservation()

    const USER_ACT_VISIT_MATCH_LANDING_APP = 'Visit Match Landing App'
    const USER_ACT_SHARE_MATCH_LANDING_APP = 'Share Match Landing App'
    const USER_ACT_VIEW_MATCH_LANDING_APP = 'View Match Landing App'
    const ipAddress = ref({})

    const isAndroidComputed = computed(() => isAndroid())
    const isIOSComputed = computed(() => isIOS())

    const deepLinkVideo = ref('fanaty://video_detail/')
    const deepLinkVideoIOS = ref(`${isDev.value ? 'https://dev-console.fanaty.com' : 'https://console.fanaty.com'}/video_detail/`)

    const dataLanding = ref({})
    const dataComputed = computed(() => dataLanding.value)
    const showComplexComputed = computed(() => dataComputed.value.complex_images && dataComputed.value.complex_images.length && dataComputed.value.complex_description)
    const activeComputed = computed(() => dataComputed.value.match_status === 'Active')
    const basicComputed = computed(() => dataComputed.value.match_status === 'Basic')
    const computedMatchCode = computed(() => JSON.parse(localStorage.getItem('matchCode')))
    const depositCardComputed = computed(() => {
      if (dataComputed.value.deposit_card && dataComputed.value.deposit_card.ending4) return dataComputed.value.deposit_card.ending4

      return ''
    })
    const showPayComputed = computed(() => {
      if (
        dataComputed.value.reservation_status !== 'cancelled'
        && dataComputed.value.payment_status === 'pending'
        && dataComputed.value.subtotal > 0
      ) return true

      return false
    })
    const matchCode = ref(null)
    const videoId = ref(null)
    const privateLanding = ref(false)
    const isDialogReport = ref(false)
    const isDialogDownload = ref(false)
    const isDialogCancel = ref(false)
    const isDialogPay = ref(false)
    const isDialogShare = ref(false)

    const fetchMatch = async () => {
      const response = await getReservation(matchCode.value)
      if (response.ok) {
        dataLanding.value = response.data

        if (dataLanding.value.reference) {
          const videoReference = decryptData(dataLanding.value.reference)
          if (videoReference.video_id) videoId.value = videoReference.video_id
        }
      } else {
        router.push({
          name: 'reservation-not-found',
          params: {
            inactive: false,
            invalid: true,
          },
        })
      }
    }

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = computedMatchCode.value
      logData.value.redirect = 'reservation'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []
      privateLanding.value = false
    }

    const resolvePosterIcon = status => {
      if (status === 'Active') return mdiPlay
      if (status === 'Processing') return mdiCircle
      if (status === 'Basic' || status === 'Recording') return mdiCircle
      if (status === 'Cancelled') return mdiCancel

      return mdiCircle
    }

    const resolvePosterIconColor = status => {
      if (status === 'Basic') return 'info'
      if (status === 'Active' || status === 'Premiun' || status === 'Recording' || status === 'Cancelled') return 'secondary'

      return 'secondary'
    }

    const fetchLogUserActivity = async action => {
      await logUserActivity({
        ip_address: privateLanding.value ? null : ipAddress.value.ip,
        user_id: privateLanding.value ? userData.value.user_id : null,
        match_code: matchCode.value,
        reference: null,
        action,
      })
    }

    const openUpdateCancel = async val => {
      isDialogCancel.value = val
    }
    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const openUpdateDownload = async val => {
      isDialogDownload.value = val
    }

    const openCloseReport = async val => {
      isDialogReport.value = val
    }

    const linkVideo = () => {
      if (isAndroid() || isIOS()) {
        const dLink = `${deepLinkVideo.value}${videoId.value}`

        const dLinkOISO = `${deepLinkVideoIOS.value}${videoId.value}`
        if (isIOS()) {
          if (isDev.value) alert(`deepLinkVideoIOS value: ${dLinkOISO}`)
          openTab(dLinkOISO)
        } else {
          window.location = dLink
        }
      }

      openUpdateDownload(true)
    }

    const onReportIssue = async val => {
      const resp = await reportIssue(matchCode.value, val)
      if (resp.ok) {
        isDialogReport.value = false
        success(resp.message)
        await fetchMatch()
      }
    }

    const onCancelReservation = async () => {
      openUpdateCancel(false)
      const response = await putCancelReservation(matchCode.value)
      if (response.ok) {
        await fetchMatch()
        success(response.message)
      }
    }

    const formatTime = time => {
      const [min] = time.split('mins')

      return `${min} mins`
    }

    const onShare = () => {
      try {
        if (isSupportedShare.value) {
          shareURL(
            'Fanaty',
            `Date: ${dataComputed.value.reservation_date_str}\nTime: ${dataComputed.value.start_time_formatted ? `${dataComputed.value.start_time_formatted} (${formatTime(dataComputed.value.reservation_time)})` : ''}\nLocation: ${dataComputed.value.complex_name ? `${dataComputed.value.complex_name} • ${dataComputed.value.pitch_name}` : ''}\n\nManage booking:\n${dataComputed.value.share_link}\n\nVideo recording available via the link.`,
            dataComputed.value.share_link,
          )
        } else {
          openUpdateShare(true)
        }
      } catch (e) {
        console.error(e)
        openUpdateShare(true)
      }
    }

    const textBreakLine = text => {
      if (text) return text.split('.').filter(e => e).map(e => (`<p class="ma-0">${e}.</p><br />`)).join('')

      return '<span></span>'
    }

    const onPay = async value => {
      const resp = await putHoldCardReservation(matchCode.value, value)
      if (resp.ok) {
        openUpdatePay(false)
        await fetchMatch()
      } else error(resp.message.text)
    }

    watch([route], async () => {
      await fetchMatch()

      await fetch('https://api.ipify.org/?format=json')
        .then(ipResponse => ipResponse.json())
        .then(async ipData => {
          ipAddress.value = ipData
        })
    })

    const resolveVideoCardBorder = computed(() => {
      if (dataComputed.value.include_video === 'N' && ['pending', 'declined'].includes(dataComputed.value.payment_status)) return 'cancelled'
      if (dataComputed.value.include_video === 'Y' && ['pending', 'declined'].includes(dataComputed.value.payment_status) && dataComputed.value.subtotal > 0) return 'pending'

      return 'primary'
    })

    const resolveVideoCardStatus = computed(() => {
      if (dataComputed.value.include_video === 'N' && dataComputed.value.subtotal === 0) return t('tooltip.disabled')
      if (dataComputed.value.include_video === 'N') return t('status.cancelled')
      if (dataComputed.value.include_video === 'Y' && (['hold', 'paid'].includes(dataComputed.value.payment_status) || dataComputed.value.subtotal === 0)) return t('tooltip.enabled')
      if (dataComputed.value.include_video === 'Y' && ['pending', 'declined'].includes(dataComputed.value.payment_status)) return t('status.pending')

      return ''
    })

    const resolveVideoCardText = computed(() => {
      if (dataComputed.value.include_video === 'N' && (dataComputed.value.reservation_status === 'cancelled' || ['hold', 'paid'].includes(dataComputed.value.payment_status))) return t('reservations.this_would_not_be_recorded')
      if (dataComputed.value.include_video === 'N' && ['pending', 'declined'].includes(dataComputed.value.payment_status)) return t('reservations.reservation_has_been_cancelled_since')
      if (dataComputed.value.include_video === 'Y' && ['pending', 'declined'].includes(dataComputed.value.payment_status)) return `${t('reservations.pay_for_booking')} ${dataComputed.value.reservation_date_str ? dataComputed.value.reservation_date_str.split(', ')[1].replace('.', '') : ''} ${dataComputed.value.start_time_formatted ? dataComputed.value.start_time_formatted : ''} ${t('reservations.to_activate_video')}`
      if (dataComputed.value.include_video === 'Y' && ['hold', 'paid'].includes(dataComputed.value.payment_status)) return t('reservations.video_recording_text')

      return ''
    })

    onMounted(async () => {
      const { name } = route.value
      if (name === 'public') await clearLogData()
      await clearVideoData()

      const { hash } = route.value.params

      // const { reauth, hash } = route.value.params
      // if (reauth) window.location.reload()
      matchCode.value = hash
      localStorage.setItem('matchCode', JSON.stringify(hash))

      await fetchMatch()

      if (!userData.value) {
        await fetch('https://api.ipify.org/?format=json')
          .then(ipResponse => ipResponse.json())
          .then(async ipData => {
            ipAddress.value = ipData
          })
      } else {
        await fetchPaymentMethods()
        privateLanding.value = true
      }
    })

    return {
      USER_ACT_VISIT_MATCH_LANDING_APP,
      USER_ACT_SHARE_MATCH_LANDING_APP,
      USER_ACT_VIEW_MATCH_LANDING_APP,
      ipAddress,
      userData,
      isDev,
      paymentMethods,
      deepLinkVideo,
      deepLinkVideoIOS,
      videoId,

      matchCode,
      privateLanding,
      isDialogReport,
      isDialogDownload,
      isDialogCancel,
      isDialogPay,
      isDialogShare,

      dataComputed,
      depositCardComputed,
      showComplexComputed,
      activeComputed,
      basicComputed,
      showPayComputed,

      isAndroidComputed,
      isIOSComputed,

      appLogoTitle: themeConfig.app.logoTitle,

      avatarText,
      openTab,
      onLogin,
      resolvePosterIcon,
      resolvePosterIconColor,
      onShare,
      linkVideo,
      fetchMatch,
      onCancelReservation,
      openCloseReport,
      onReportIssue,
      openUpdateDownload,
      openUpdateCancel,
      openUpdatePay,
      openUpdateShare,
      textBreakLine,
      fetchLogUserActivity,
      onLogout,
      onPay,
      defaultDownloadAppByOS,
      formatTime,
      resolveVideoCardStatus,
      resolveVideoCardText,
      resolveVideoCardBorder,

      t,

      icons: {
        mdiTrayArrowUp,
        mdiDotsVertical,
        mdiCircle,
        mdiMapMarker,
        mdiCancel,
        mdiPlay,
        mdiStarOutline,
        mdiVideo,
        mdiPencil,
        mdiMovieOpenPlayOutline,
        mdiHelpCircleOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/reservation.scss';

.reservation-wrapper {
  background-color: #222222;
}

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>
